import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk"; //Middleware
import rootReducer from "./reducers"; //Combine all reducers into this file, so we don't import lots of files

const initialState = {}; //Initial states will be defined in reducers

const middleware = [thunk];

//Syntax similar to useReducer => first arg is the root reducer, second is the initial state
//Except third arg is middleware (third arg in useReducer is 'lazy init function')
const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;

//Import {Provider} from 'react-redux' in App.js