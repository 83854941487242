import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getProfiles } from "../../actions/profile";
import ProfileItem from "./ProfileItem";
import Spinner from "../layouts/Spinner";

const Profiles = () => {
  const dispatch = useDispatch();
  const { profiles, loading } = useSelector((state) => state.profile);

  useEffect(() => {
    dispatch(getProfiles());
  }, [dispatch]);

  return (
    <Fragment>
      <h1 className="large text-primary">Developers</h1>
      <p className="lead">
        <i className="fab fa-connectdevelop">
          Browse and connect with developers
        </i>
      </p>
      {loading ? (
        <Spinner />
      ) : (
        <div className="profiles">
          {profiles.length > 0 ? (
            profiles.map((profile) => (
              <ProfileItem key={profile._id} profile={profile}>
                test
              </ProfileItem>
            ))
          ) : (
            <h4>No profiles found</h4>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default Profiles;
