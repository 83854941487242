import React from "react";
import Spinner from "../layouts/Spinner";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

/**
 * For routes that are private, redirect the client if not authenticated
 * @param {*} Component called in \<PrivateRoute component={componentName}\>
 * @returns 
 */
const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, loading } = useSelector((state) => state.auth);

  return loading ? (
    <Spinner />
  ) : (
    //<Route {...rest} render={(<Redirect to="/login">)} />
    //<Route {...rest} render={(<Component {..props}>)} />
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated && !loading ? (
          <Redirect to="/login" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PrivateRoute;
